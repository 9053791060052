    <template>
    <div class="main" @click="fpttshow=false">
        <van-cell-group  >
            <van-cell center is-link @click="fplxshow=true">
                <template #title>
                    <van-field readonly required v-model="fplx" label="发票类型"/>
                </template>
            </van-cell>
            <van-cell center is-link @click="fpttClick">
              <template #title  style="position: relative;">
                <van-field required  @click.stop v-model="customObj.gfmc"  v-on:input="inputFunc" placeholder="点击选择发票抬头" label="发票抬头"/>

              </template>
            </van-cell>
            <div v-show="fpttshow"  style="border:0px solid red; z-index: 9999; min-height: 100px; width: 100%; position: absolute; text-align: center">
                <div style="margin-left:20px; margin-right:20px; background: white; border-radius:5px;
                    border: 1px solid #EEEEEE; box-shadow:1px 1px 8px #eee;border-top:0px;" @blur="fpttshow=false">
                  <div v-for="(fpttcolun,index) in fpttcoluns" :key="index" @click="fpttConfrm(fpttcolun.text,index)" style="padding: 10px; text-align: left;">
                    {{ fpttcolun.text }}
                    <br><span style="font-size: 0.9em; color: #C8C9CC;">{{ fpttcolun.taxNo }}</span>
                  </div>
                </div>
            </div>

            <van-cell center >
                <template #title>
                    <van-field required v-model="customObj.nsrsbh" @blur="changeIpt" placeholder="请填写企业税号" label="企业税号"/>
                   
                </template>
            </van-cell>
            <van-cell center  v-if="zkState">
                <template #title>
                    <van-field  v-model="customObj.gfdz" @blur="changeIpt" placeholder="请填写地址" label="地址"/>
                </template>
            </van-cell>
            <van-cell center  v-if="zkState">
                <template #title>
                    <van-field  v-model="customObj.yhzh" @blur="changeIpt" placeholder="请填写开户行与账号" label="开户行"/>
                </template>
            </van-cell>
            <van-cell center  v-if="zkState">
                <template #title>
                    <van-field  v-model="customObj.bz" @blur="changeIpt" placeholder="请填写备注" label="备注"/>
                </template>
            </van-cell>
            <van-cell center @click="zkState = !zkState">
                <template #title>
                    <div style="text-align: center; color: #1989fa; font-size: 16px">{{zkState? "收起 ↑": "展开 ↓"}}</div>
                </template>
            </van-cell>
        </van-cell-group>

      <!--<div style="color:#FF5722;font-size:1em;margin-top:18px; margin-left:10px;">温馨提示：信息保存成功后，请到PC端开具</div>
-->
        <van-cell-group title=" ">
            <van-cell center >
                <template #title>
                    <van-field required v-model="customObj.gfdh" @blur="changeIpt" placeholder="请填写交付手机" label="交付手机"/>
                </template>
            </van-cell>
            <van-cell center >
                <template #title>
                    <van-field v-model="customObj.gfyx" @blur="changeIpt" placeholder="请填写交付邮箱" label="交付邮箱"/>
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group title=" ">
            <van-cell title="发票明细" to="/invoiceAdd">
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #right-icon>
                    <van-icon name="add-o" size="18px"/>
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group>
            <van-cell center v-for="(item,index) in querydata.ItemList" :key="index">
            <van-row gutter="10">
                <van-col span="8">{{item.ItemName}}</van-col>
                <van-col span="6">{{item.TaxRate}}</van-col>
                <van-col span="8">{{item.Amount}}</van-col>
                <van-col span="2" @click="removeRow(item,index)"><van-icon name="cross"/></van-col>
            </van-row>
            </van-cell>
        </van-cell-group>

        <van-submit-bar :price="zongjia" text-align="left" 	button-type="primary"  label="总金额（元）" button-text="提交开票" @submit="onSubmit" >
        </van-submit-bar>

        <van-popup v-model:show="fplxshow" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @cancel="fplxshow = false"
                    @confirm="onConfirm"
            />
        </van-popup>
        
<!--        <van-popup v-model:show="fpttshow" position="bottom">-->
<!--            <van-picker-->
<!--                    show-toolbar-->
<!--                    :columns="fpttcoluns"-->
<!--                    @cancel="fpttshow = false"-->
<!--                    @confirm="fpttConfrm"-->
<!--            />-->
<!--        </van-popup>-->


    </div>
</template>

<script>
    import {defineComponent, getCurrentInstance, reactive, ref,onMounted} from 'vue'
    import { Dialog, Notify} from 'vant';

    import Request from '../../api/invoice'
    export default defineComponent({
        name: "invoicing",
        setup(){
            const fplx = ref("点击选择发票类型");
            const fplxshow = ref(false);
            const {proxy} = getCurrentInstance();
            // const message =  proxy.$root.$route.params.item !== undefined? JSON.parse(proxy.$root.$route.params.item): undefined;
            const sesfkttData = sessionStorage.getItem("fpkj-sdkp-fptt")!== null? JSON.parse(sessionStorage.getItem("fpkj-sdkp-fptt")): null;
            const addfkttData = sessionStorage.getItem("fpkj-sdkp-addmx")!== null? JSON.parse(sessionStorage.getItem("fpkj-sdkp-addmx")): null;
            const fplxData = sessionStorage.getItem("fpkj-sdkp-fplx")!== null? JSON.parse(sessionStorage.getItem("fpkj-sdkp-fplx")): null;
            const kyfplist = sessionStorage.getItem("sy-fpzl-set")!== null? JSON.parse(sessionStorage.getItem("sy-fpzl-set")): null;
            
            console.log(sesfkttData);
            console.log(addfkttData);
            // console.log(message);
            // console.log(addata);
            // console.log(backdata);
            // const obj = message !== undefined? message: {
            //     id:"",
            //     nsrsbh: "",
            //     gfdh: "",
            //     gfmc: "",
            //     gfdz: "",
            //     yhzh: "",
            //     bz: "",
            //     gfyh: "",
            //     gfyx: "",
            // };
            const obj = sesfkttData !== null? sesfkttData: {
                id:"",
                nsrsbh: "",
                gfdh: "",
                gfmc: "",
                gfdz: "",
                yhzh: "",
                bz: "",
                gfyh: "",
                gfyx: "",
            };
            const columns =ref(['增值税专用发票', '增值税普通发票（纸票）', '增值税普通发票（卷式）', '增值税电子普通发票', '增值税电子专用发票']);
            const customObj = reactive(obj);
            const zkState = ref(false);
            const zje = ref(0);
            const querydata = reactive({
                InvoiceType:"",
                PurchaserTaxID: customObj.nsrsbh,
                PurchaserName: customObj.gfmc,
                PurchaserAddr: customObj.gfdz,
                PurchaserPhone: customObj.gfdh,
                PurchaserBank: customObj.gfyh,
                PurchaserAccount: customObj.yhzh,
                PurchaserEmail: customObj.gfyx,
                PurchaserMobile: customObj.gfdh,
                TotalAmountTaxExcluded: 0,
                TotalTax: 0,
                TotalAmount: 0,
                ItemList: [],
            });
            if(kyfplist!=null){
                console.log(kyfplist);
                columns.value=kyfplist;
                console.log(columns);
            }
           
            if(fplxData!=null){
                querydata.InvoiceType = fplxData.idx.toString();
                fplx.value = fplxData.val;
            }
            
            if(addfkttData!=undefined && addfkttData!=null && addfkttData.length>0){
                for(var i=0;i<addfkttData.length;i++){
                    var add = addfkttData[i].addata;
                    var back = addfkttData[i].backdata;
                    
                    var ovj = {
                        ItemName: add.spmc,
                        UnitPrice: add.hsdj,
                        UnitPriceTaxExcluded: add.bhsdj,
                        Amount: add.kpje,
                        AmountTaxExcluded: add.je1,
                        Quantity: add.sl,
                        TaxRate: add.rate,
                        Tax: add.se1,
                        Specifications: add.gg,
                        Unit: add.dw,
                        DiscountAmountTaxExcluded: "",
                        TaxExcludingAmount: "",
                        CatalogName: back.hwhlwmc,
                        CatalogCode: back.splb,
                        HasPreferentialPolicy: back.HasPreferentialPolicy,
                        PreferentialPolicy: back.yhzc,
                        TaxFreeType: back.lsllx,
                        id:add.mynub,
                    }
                    querydata.ItemList.push(ovj);
                }
                    
            }
            
            const zongjia=ref(0);

            const huizong = () => {
                if(addfkttData!=null && addfkttData.length>0){
                    for(var i=0;i<addfkttData.length;i++){
                        querydata.TotalAmount += parseFloat(addfkttData[i].addata.kpje);
                        querydata.TotalAmountTaxExcluded += parseFloat(addfkttData[i].addata.je1);
                        querydata.TotalTax += parseFloat(addfkttData[i].addata.se1);

                    }
                    querydata.TotalAmount = querydata.TotalAmount.toFixed(2);
                    querydata.TotalAmountTaxExcluded = querydata.TotalAmountTaxExcluded.toFixed(2);
                    querydata.TotalTax = querydata.TotalTax.toFixed(2);
                    console.log(querydata);
                    console.log(zongjia);
                    zongjia.value=parseFloat(querydata.TotalAmount)*100;
                }
            };

            const onConfirm = (value) => {
                fplx.value = value;
                fplxshow.value = false;
                if(value=="增值税专用发票"){
                    querydata.InvoiceType='1';
                }else if(value=="增值税普通发票（纸票）"){
                    querydata.InvoiceType='2';
                }else if(value=="增值税普通发票（卷式）"){
                    querydata.InvoiceType='3';
                }else if(value=="增值税电子普通发票"){
                    querydata.InvoiceType='4';
                }else if(value=="增值税电子专用发票"){
                    querydata.InvoiceType='8';
                }
                var oblx ={
                    val:value,
                    idx:querydata.InvoiceType
                }
                sessionStorage.setItem('fpkj-sdkp-fplx',JSON.stringify(oblx));
            };
            const fpttClick = ()=>{
                proxy.$root.$router.push({
                    path:"/custom",
                    query:{method: "select"},
                    meta: {keepAlive: true }// 需要被缓存
                })
            };
            const onSubmit = ()=>{
                console.log("开票");
                console.log(querydata);
                console.log(querydata.InvoiceType);
                console.log(querydata.InvoiceType.length);
                if(querydata.InvoiceType=="" || querydata.InvoiceType==null){
                    Notify({ type: 'warning', message: '请选择发票类型!' });
                    return
                }
                if(querydata.PurchaserName==""){
                    Notify({ type: 'warning', message: '请选择发票抬头!' });
                    return
                }
                if(querydata.PurchaserTaxID==""){
                    Notify({ type: 'warning', message: '请填写企业税号!' });
                    return
                }
                if(querydata.PurchaserPhone==""){
                    Notify({ type: 'warning', message: '请填写手机号!' });
                    return
                }
                Request.fp_kp(querydata).then(res=>{
                    console.log('res=>',res);   
                    if(res.returnCode == '00'){
                        Dialog.alert({
                            title: '温馨提示',
                            message: res.returnMessage,
                        }).then(() => {
                            sessionStorage.removeItem("fpkj-sdkp-fptt");
                            sessionStorage.removeItem("fpkj-sdkp-addmx");
                            sessionStorage.removeItem("fpkj-sdkp-mynub");
                            sessionStorage.removeItem("fpkj-sdkp-fplx");
                            proxy.$root.$router.go(0);
                        });
                    }else{
                        Dialog.alert({
                            title: '温馨提示',
                            message: res.returnMessage,
                        }).then(() => {
                        });
                    }
                    
                }).catch(err=>{
                    console.log('err=>',err);   
                })
            };
            const removeRow = (row,idx)=>{
                console.log(row);
                console.log(idx);
                querydata.ItemList.splice(idx,1); 
                console.log(querydata.ItemList);
                for(var i=0;i<addfkttData.length;i++){
                    if(row.id == addfkttData[i].addata.mynub){
                        addfkttData.splice(i,1); 
                    }
                }
                // for(var i=0;i<addfkttData.length;i++){
                //     jisuan+=parseInt(addfkttData[i].addata.kpje).toFixed(2);
                // }
                
                console.log(addfkttData);
                sessionStorage.setItem('fpkj-sdkp-addmx',JSON.stringify(addfkttData))
            };
            const changeIpt = ()=>{
                console.log('customObj');
                console.log(customObj);
                querydata.PurchaserTaxID = customObj.nsrsbh,
                querydata.PurchaserName = customObj.gfmc,
                querydata.PurchaserAddr = customObj.gfdz,
                querydata.PurchaserPhone = customObj.gfdh,
                querydata.PurchaserBank = customObj.gfyh,
                querydata.PurchaserAccount = customObj.yhzh,
                querydata.PurchaserEmail = customObj.gfyx,
                querydata.PurchaserMobile = customObj.gfdh,
                sessionStorage.setItem('fpkj-sdkp-fptt',JSON.stringify(customObj));
            };
            
            const fpttshow = ref(false);
            const fpttcoluns = ref([]);
            const fpttArr = ref([]);
            const fpttConfrm= (value,index)=>{
                console.log(value);
                customObj.gfmc=value;
                fpttshow.value = false;
                console.log(fpttArr.value)
                for(var i=0;i<fpttArr.value.length;i++){
                    if(value==fpttArr.value[i].label &&i ==index){
                        customObj.nsrsbh = fpttArr.value[i].value.taxNo
                    }
                }
            };
            const inputFunc = ()=>{
                console.log(customObj.gfmc);
                var changdu  = customObj.gfmc.length
                if(changdu>3){
                    console.log(changdu);
                    Request.fp_cx_mhsearch({key:customObj.gfmc}).then(res=>{
                        console.log(res);
                        fpttcoluns.value=[];
                        if(res.rows.length>0){
                            for(var i=0;i<res.rows.length;i++){
                                // var aa=res.rows[i].label;
                                // fpttcoluns.value.push(aa);
                              var item = res.rows[i].value;
                              fpttcoluns.value.push({ text: item.name,taxNo: item.taxNo });
                            }
                            fpttshow.value = true;
                        }
                        console.log(fpttcoluns);
                        fpttArr.value = res.rows;
                        
                    }).catch(err=>{
                        console.log('err=>',err);   
                    })
                }
                
            }


            onMounted(()=>{
                huizong();
            })
            return{
                fplx,
                fplxshow,
                customObj,
                columns,
                zkState,
                zje,
                onConfirm,
                fpttClick,
                onSubmit,
                querydata,
                removeRow,
                zongjia,
                huizong,
                changeIpt,
                inputFunc,
                fpttshow,
                fpttcoluns,
                fpttConfrm,
                fpttArr
            }
        }
    });
</script>

<style scoped>
    .main {
        background: #eee;
        min-height: 150vh;
        position: relative;
    }

    .bottom {
        position: absolute;
        width: 100vw;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
        background-color: white;
    }
    .buyprice {
        flex: 1;
        padding-right: 8px;
        text-align: right;
        display: flex;
        flex-direction: column;
    }
    .p1 {
        font-size: 10px;
        color: red;
        float: left;

    }
    .p2 {
        font-size: 12px;
        float: left;

        /* color: #15c481; */
    }
</style>